import React, { FC, useEffect, useState } from 'react';
import styles from './userList.module.css';
import headingStyles from '../../assets/styles/heading.module.css';
import buttonStyles from '../../assets/styles/customButton.module.css';
import CustomButton from '../CustomButton';
import { SocialLinkTypes, UserListType, UserTypes } from '../../types';
import { CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { showErrorAlert, showSuccessAlert } from '../../store/actions/alerts';
import { ApiClient } from '../../services/apiClient';
import { KYC_LEVEL, KYC_STATUS, USER_NOT_FOUND } from '../../helpers/constants';
import { formatDate } from '../../helpers/utils';
import GenericModal from '../GenericModal';
import TransferCoiin from './TransferCoiin';
import AddSubscription from './AddSubscription';

const UserInfo: FC<UserTypes> = ({ userId }: UserTypes) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState<UserListType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<number>(new Date().getTime());
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [banLoading, setBanLoading] = useState<boolean>(false);
  const [unbanLoading, setUnbanLoading] = useState<boolean>(false);
  const [resetLoading, setResetLoading] = useState<boolean>(false);
  const [tiktokLink, setTiktokLink] = useState<SocialLinkTypes>();
  const [twitterLink, setTwitterLink] = useState<SocialLinkTypes>();
  const [level1Loading, setLevel1Loading] = useState<boolean>(false);
  const [level2Loading, setLevel2Loading] = useState<boolean>(false);
  const [transferCoiinModal, openTransferCoiinModal] = useState<boolean>(false);
  const [awardPremiumModal, openAwardPremiumModal] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    ApiClient.getUserDetails(userId)
      .then((response) => {
        setUser(response);
        const twitter = response.social_link.find((a) => a?.type == 'twitter');
        setTwitterLink(twitter);
        const tiktok = response.social_link.find((a) => a?.type == 'tiktok');
        setTiktokLink(tiktok);
      })
      .catch((error) => dispatch(showErrorAlert((error as Error).message)))
      .finally(() => setLoading(false));
  }, [refetch]);

  // Handle user status
  const handleUserStatus = (params: { status: boolean }) => {
    const { status } = params;
    status ? setUnbanLoading(true) : setBanLoading(true);
    if (!user?.id) return dispatch(showErrorAlert(USER_NOT_FOUND));
    ApiClient.updateUserStatus({ userId: user.id, status })
      .then(() => {
        dispatch(showSuccessAlert(status ? 'User activated successfully' : 'User banned'));
        setRefetch(new Date().getTime());
      })
      .catch((error) => dispatch(showErrorAlert((error as Error).message)))
      .finally(() => (status ? setUnbanLoading(false) : setBanLoading(false)));
  };

  //Handle reset user password
  const handleResetPassword = () => {
    setResetLoading(true);
    if (!user?.id) return dispatch(showErrorAlert(USER_NOT_FOUND));
    ApiClient.resetUserPassword({ userId: user?.id })
      .then((response) => {
        dispatch(showSuccessAlert(response));
      })
      .catch((error) => dispatch(showErrorAlert((error as Error).message)))
      .finally(() => setResetLoading(false));
  };

  // Delete user
  const handleDeleteUser = async () => {
    setDeleteLoading(true);
    if (!user?.id) return dispatch(showErrorAlert(USER_NOT_FOUND));
    ApiClient.deleteUser({ userId: user.id })
      .then((response) => {
        dispatch(showSuccessAlert(response.message));
        setRefetch(new Date().getTime());
      })
      .catch((error) => dispatch(showErrorAlert((error as Error).message)))
      .finally(() => setDeleteLoading(false));
  };

  const approveUserKyc = (level: KYC_LEVEL) => {
    if (!user?.id) return;
    if (level === KYC_LEVEL.LEVEL1) setLevel1Loading(true);
    if (level === KYC_LEVEL.LEVEL2) setLevel2Loading(true);
    ApiClient.approveKyc({ userId: user.id, level })
      .then(() => {
        dispatch(showSuccessAlert(`KYC ${level} approved.`));
        setRefetch(new Date().getTime());
      })
      .catch((error) => dispatch(showErrorAlert((error as Error).message)))
      .finally(() => {
        if (level === KYC_LEVEL.LEVEL1) setLevel1Loading(false);
        if (level === KYC_LEVEL.LEVEL2) setLevel2Loading(false);
      });
  };

  return (
    <div>
      {loading && (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )}
      {user && (
        <div className="flex flex-col">
          <GenericModal open={transferCoiinModal} onClose={() => openTransferCoiinModal(false)} size="small">
            <TransferCoiin userId={userId} />
          </GenericModal>
          <GenericModal open={awardPremiumModal} onClose={() => openAwardPremiumModal(false)} size="small">
            <AddSubscription userId={userId} />
          </GenericModal>

          <div className={styles.userInfoWrapper}>
            <div className={styles.details}>
              <h2 className={headingStyles.heading}>User Details</h2>
              <div className={styles.boxStyle}>
                <h4>User Name:</h4>
                <p>{user?.profile?.username}</p>
              </div>
              <div className={styles.boxStyle}>
                <h4>Email:</h4>
                <p>{user.email} </p>
              </div>
              <div className={styles.boxStyle}>
                <h4>Kyc Status:</h4>
                <p>{user.kycStatus}</p>
              </div>
              <div className={styles.boxStyle}>
                <h4>Last Login:</h4>
                <p>{new Date(user.lastLogin).toDateString() || 'Not login ever'}</p>
              </div>
              <div className={styles.boxStyle}>
                <h4>User Status:</h4>
                <p className={`${user.active === true ? 'text-green-700' : 'text-red-700'}`}>
                  {user.active === true ? 'Active' : 'Banned'}
                </p>
              </div>
              <div className={styles.boxStyle}>
                <h4>Active Since:</h4>
                <p>{new Date(user.createdAt).toDateString()}</p>
              </div>
              <div className={styles.boxStyle}>
                <h4>Post Frequency:</h4>
                <p>{user?.social_post?.length || 0}</p>
              </div>
              <div className={styles.boxStyle}>
                <h4>Twitter Username:</h4>
                <p>{twitterLink?.username || ''}</p>
              </div>
              <div className={styles.boxStyle}>
                <h4>Twitter followers:</h4>
                <p>{twitterLink?.followerCount || 0}</p>
              </div>
              <div className={styles.boxStyle}>
                <h4>Tiktok Username:</h4>
                <p>{tiktokLink?.username || ''}</p>
              </div>
              <div className={styles.boxStyle}>
                <h4>Tiktok followers:</h4>
                <p>{tiktokLink?.followerCount || 0}</p>
              </div>
              <div className={styles.boxStyle}>
                <h4>Free Credits Expiry Date:</h4>
                <p>{user.freeCreditsExpiryDate ? formatDate(user.freeCreditsExpiryDate) : ''}</p>
              </div>
              <div className={styles.boxStyle}>
                <h4>Subscription Expiry Date:</h4>
                <p>{user.subscriptionExpiryDate ? formatDate(user.subscriptionExpiryDate) : ''}</p>
              </div>
            </div>

            <div className={styles.actions}>
              <div>
                {user.active === false ? (
                  <CustomButton
                    className={`${buttonStyles.successButton} ${styles.actionButton}`}
                    onClick={() => handleUserStatus({ status: true })}
                    loading={unbanLoading}
                  >
                    Activate User
                  </CustomButton>
                ) : (
                  <CustomButton
                    className={`${buttonStyles.secondaryButton2} ${styles.actionButton}`}
                    onClick={() => handleUserStatus({ status: false })}
                    loading={banLoading}
                  >
                    Ban User
                  </CustomButton>
                )}
              </div>
              <CustomButton
                className={`${buttonStyles.successButton} ${styles.actionButton}`}
                onClick={handleResetPassword}
                loading={resetLoading}
              >
                Reset Password
              </CustomButton>
              <CustomButton
                className={`${buttonStyles.secondaryButton} ${styles.actionButton}`}
                onClick={handleDeleteUser}
                loading={deleteLoading}
              >
                Delete User
              </CustomButton>
              <CustomButton
                className={`${buttonStyles.buttonPrimary} ${styles.actionButton}`}
                onClick={() => {
                  approveUserKyc(KYC_LEVEL.LEVEL1);
                }}
                disabled={user.kycLevel1 === KYC_STATUS.APPROVED ? true : false}
                loading={level1Loading}
              >
                Approve KYC Level 1
              </CustomButton>
              <CustomButton
                className={`${buttonStyles.buttonPrimary} ${styles.actionButton}`}
                onClick={() => {
                  approveUserKyc(KYC_LEVEL.LEVEL2);
                }}
                disabled={user.kycLevel2 === KYC_STATUS.APPROVED ? true : false}
                loading={level2Loading}
              >
                Approve KYC Level 2
              </CustomButton>

              <CustomButton
                className={`${buttonStyles.filledButton} ${styles.actionButton}`}
                onClick={() => {
                  openTransferCoiinModal(true);
                }}
              >
                Transfer Coiin
              </CustomButton>

              <CustomButton
                className={`${buttonStyles.filledButton2} ${styles.actionButton}`}
                onClick={() => {
                  openAwardPremiumModal(true);
                }}
              >
                Add Subscription
              </CustomButton>
            </div>
          </div>

          <div className={styles.identitiesWrapper}>
            <h2 className={`${headingStyles.heading} mb-2`}>Identities - {user.identities.length}</h2>
            <div className={styles.tableWrapper}>
              <table className={styles.table}>
                <thead>
                  <tr className={styles.tableHeadRow}>
                    <th className={styles.tableColumn}>PublicKey</th>
                    <th className={styles.tableColumn}>Proxy Validator</th>
                    <th className={styles.tableColumn}>Independent Validator</th>
                  </tr>
                </thead>
                <tbody>
                  {user.identities.length > 0 ? (
                    user.identities.map((item) => (
                      <tr className={styles.tableBodyRow} key={item.id}>
                        <td className={styles.tableColumn}>
                          <span className={styles.publicKey}>{item.publicKey}</span>
                        </td>
                        <td className={styles.tableColumn}>{String(item.proxyValidator)}</td>
                        <td className={styles.tableColumn}>{String(item.independentValidator)}</td>
                      </tr>
                    ))
                  ) : (
                    <p className="p-2">No transaction record found</p>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserInfo;
