import React, { ChangeEvent, FC } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import './selectCurrency.scss';

interface ISelectCurrencyProps {
  label: string;
  value: string | null;
  handleOnChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  options: string[];
}

const SelectCurrency: FC<ISelectCurrencyProps> = ({ label, value, handleOnChange, options }) => {
  console.log(value);
  return (
    <FormControl className="w-full" variant="outlined">
      <InputLabel id="currency-label">{label}</InputLabel>
      <Select
        value={value}
        defaultValue={value}
        className={`selectInput`}
        onChange={(event: ChangeEvent<unknown>) => {
          handleOnChange(event as ChangeEvent<HTMLSelectElement>);
        }}
      >
        {options?.map((item) => {
          return (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
    // <Autocomplete
    //   value={value}
    //   onChange={(event: ChangeEvent<any>, newValue: string | null) => {
    //     handleOnChange(event, newValue);
    //   }}
    //   inputValue={inputValue}
    //   options={options}
    //   size={'medium'}
    //   renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
    //   className={`${className} autoCompleteField`}
    //   renderOption={(option) => {
    //     const value = option.split('-');
    //     return (
    //       <div className="flex justify-between w-full">
    //         <p>{value[0]}</p>
    //         <p className="text-xs">{value[1]}</p>
    //       </div>
    //     );
    //   }}
    // />
  );
};

export default SelectCurrency;
