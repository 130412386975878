import axios, { AxiosError } from 'axios';
import { urls } from '../apiConfig.json';
import {
  CampaignScoreTypes,
  CompleteEmailVerificationPayload,
  RegisterBrandPayload,
  SuccessResponse,
  FundingWallet,
  WithdrawPayload,
  TwoFactorAuthPayload,
  UpdateProfilePayload,
  UpdateProfileTypes,
  KycResponse,
  VerifyKycTypes,
  ProfileTypes,
  PendingCampaignPayload,
  DashboardStatsTypes,
  UserStatTypes,
  UserData,
  CampaignListVars,
  PaginatedCampaignResultsV2,
  PaymentMethodTypes,
  CampaignMetricTypes,
  SupportedCurrenciesTypes,
  AddPaymentMethodTypes,
  PurchaseCoiinPayload,
  PurchaseCoiinTypes,
  DashboardMetricParams,
  UserDemographicsTypes,
  CoiinValueType,
  CoiinTransferTypes,
  TiersEstimateTypes,
  NewCampaignVars,
  CampaignCreationResponse,
  Campaign,
  OrganizationEmployees,
  UserTransactionHistoryTypes,
  UserListType,
  CurrencyTypes,
  RedemptionTypes,
  UserStatisticTypes,
  DepositAddressTypes,
  ParticipantListParams,
  ParticipantListTypes,
  AIModel,
  UpdateCreditsPayload,
  CoiinMetrics,
  WithdrawalRequest,
  UserDocumentVerification,
  AddSubscriptionParams,
} from '../types';
import { StartEmailVerificationPayload } from '../types.d';
import { APPROVAL_STATUS, KYC_LEVEL } from '../helpers/constants';

const env = process.env.REACT_APP_STAGE || 'local';
const SOMETHING_WENT_WRONG = 'Something went wrong!';

export class ApiClient {
  private static baseUrl = process.env.REACT_APP_LOCAL_URL || ((urls as { [key: string]: string })[env] as string);
  private static requestInstance = axios.create({
    withCredentials: true,
    baseURL: ApiClient.baseUrl,
  });

  public static async login(payload: { email: string; password: string }): Promise<UserData> {
    try {
      return (await this.requestInstance.post('/admin/auth/login', payload)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).message || SOMETHING_WENT_WRONG);
    }
  }

  public static async logout(): Promise<SuccessResponse> {
    try {
      return (await this.requestInstance.post('/admin/auth/logout')).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).message || SOMETHING_WENT_WRONG);
    }
  }

  public static async startEmailVerification(payload: StartEmailVerificationPayload): Promise<SuccessResponse> {
    try {
      return (await this.requestInstance.post('/admin/auth/verification/start', payload)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).message || SOMETHING_WENT_WRONG);
    }
  }

  public static async completeEmailVerification(
    payload: CompleteEmailVerificationPayload,
  ): Promise<{ verificationToken: string }> {
    try {
      return (await this.requestInstance.post('/admin/auth/verification/complete', payload)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async registerBrand(payload: RegisterBrandPayload): Promise<SuccessResponse> {
    try {
      return (await this.requestInstance.post('/v1/organization/register', payload)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getAdminData(): Promise<UserData> {
    try {
      return (await this.requestInstance.get('/admin')).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getFundingWallet(): Promise<FundingWallet[]> {
    try {
      return (await this.requestInstance.get('/v1/funding-wallet')).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getCampaignPostCount(campaignId: string): Promise<{ data: { count: number } }> {
    try {
      return (await this.requestInstance.get(`/admin/social/post/count?campaignId=${campaignId}`)).data;
    } catch (error) {
      console.log(error);
      throw new Error((error as Error).message);
    }
  }

  public static async getCampaignScore(campaignId: string): Promise<{ data: CampaignScoreTypes }> {
    try {
      return await (
        await this.requestInstance.get(`/admin/social/campaign/score?campaignId=${campaignId}`)
      ).data;
    } catch (error) {
      console.log(error);
      throw new Error((error as Error).message);
    }
  }

  public static async withdrawFunds(payload: WithdrawPayload): Promise<SuccessResponse> {
    try {
      return (await this.requestInstance.post('/admin/tatum/withdraw', payload)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getProfile(): Promise<{ data: ProfileTypes }> {
    try {
      return (await this.requestInstance.get('/v1/organization/profile')).data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async twoFactorAuth(payload: TwoFactorAuthPayload): Promise<SuccessResponse> {
    try {
      return (await this.requestInstance.put('/v1/organization/2fa', payload)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async updateProfile(payload: UpdateProfilePayload): Promise<UpdateProfileTypes> {
    try {
      return (await this.requestInstance.put('/v1/organization/profile', payload)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async registerKyc(payload: VerifyKycTypes): Promise<KycResponse> {
    try {
      return (await this.requestInstance.post('/admin/kyc/verify', payload)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async registerUserDocumentVerification(payload: UserDocumentVerification): Promise<KycResponse> {
    try {
      return (await this.requestInstance.post('/admin/kyc/user/verify/manual', payload)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async updatePendingCampaignStatus(payload: PendingCampaignPayload): Promise<SuccessResponse> {
    try {
      return (await this.requestInstance.put('/admin/campaign/status', payload)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getDashboardStats(params: DashboardMetricParams): Promise<DashboardStatsTypes> {
    try {
      const { campaignId, startDate, endDate, month } = params;
      return (
        await this.requestInstance.get(
          `/admin/campaign/dashboard/metrics?campaignId=${campaignId}&startDate=${startDate}&endDate=${endDate}&month=${month}`,
        )
      ).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getUserStats(): Promise<UserStatTypes> {
    try {
      return (await this.requestInstance.get(`/admin/user/stats`)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getLiteCampaigns(): Promise<{ id: string; name: string }[]> {
    try {
      return (await this.requestInstance.get(`/admin/campaign/list/lite`)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getCampaigns(payload: CampaignListVars): Promise<PaginatedCampaignResultsV2> {
    try {
      return (
        await this.requestInstance.get(
          `/v1/campaign?skip=${payload.skip}&take=${payload.take}&state=${payload.state}&status=${payload.status}`,
        )
      ).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getPaymentMethods(): Promise<PaymentMethodTypes[]> {
    try {
      return (await this.requestInstance.get(`/v1/stripe/payment-methods`)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getCampaignMetrics(campaignId: string): Promise<CampaignMetricTypes> {
    try {
      return (await this.requestInstance.get(`/admin/campaign/campaign-metrics?campaignId=${campaignId}`)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getSupportedCurrencies(): Promise<SupportedCurrenciesTypes[]> {
    try {
      return (await this.requestInstance.get(`/admin/tatum/currencies`)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async removePaymentMethod(payload: { paymentMethodId: string }): Promise<SuccessResponse> {
    try {
      return (await this.requestInstance.post(`/v1/stripe/remove-payment-method`, payload)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async addPaymentMethod(): Promise<AddPaymentMethodTypes> {
    try {
      return (await this.requestInstance.post(`/v1/stripe/add-payment-method`)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async purchaseCoiin(payload: PurchaseCoiinPayload): Promise<PurchaseCoiinTypes> {
    try {
      return (await this.requestInstance.post(`/v1/stripe/purchase-coiin`, payload)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getDemographics(payload: DashboardMetricParams): Promise<UserDemographicsTypes> {
    const { campaignId, endDate, month, startDate, skip, take } = payload;
    try {
      return (
        await this.requestInstance.get(
          `/admin/participant/demographic?campaignId=${campaignId}&month=${month}&startDate=${startDate}&endDate=${endDate}&skip=${skip}&take=${take}`,
        )
      ).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }
  public static async getDemographicsCount(payload: DashboardMetricParams): Promise<UserDemographicsTypes> {
    const { campaignId, endDate, month, startDate } = payload;
    try {
      return (
        await this.requestInstance.get(
          `/admin/participant/demographic-count?campaignId=${campaignId}&month=${month}&startDate=${startDate}&endDate=${endDate}`,
        )
      ).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getCoiinValue(): Promise<CoiinValueType> {
    try {
      return (await this.requestInstance.get(`/admin/tatum/coiin/value`)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async transferCoiin(payload: CoiinTransferTypes): Promise<{ success: boolean }> {
    try {
      return (await this.requestInstance.post(`/admin/user/transfer/coiin`, payload)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async addSubscription(payload: AddSubscriptionParams): Promise<{ message: string }> {
    try {
      return (await this.requestInstance.post(`/admin/credits/user/subscription`, payload)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getEstimatedInfluence(payload: {
    budget: string;
    currency: string;
  }): Promise<TiersEstimateTypes[]> {
    try {
      const { budget, currency } = payload;
      return (
        await this.requestInstance.get(
          `/admin/campaign/influence/estimate?campaignBudget=${budget}&currencyType=${currency}`,
        )
      ).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async createNewCampaign(payload: NewCampaignVars): Promise<{ data: CampaignCreationResponse }> {
    try {
      return (await this.requestInstance.post(`/admin/campaign/create-campaign`, payload)).data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async updateCampaign(payload: NewCampaignVars & { id: string }): Promise<CampaignCreationResponse> {
    try {
      return (await this.requestInstance.post(`/admin/campaign/update-campaign`, payload)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getCampaignById(campaignId: string): Promise<Campaign> {
    try {
      return (await this.requestInstance.get(`/admin/campaign/one/${campaignId}`)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getOrganizationEmployees(): Promise<OrganizationEmployees> {
    try {
      return (await this.requestInstance.get(`/v1/organization/list-employees`)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async deleteOrganizationUser<T>(adminId: string): Promise<T> {
    try {
      return (await this.requestInstance.post(`/v1/organization/delete-user/${adminId}`)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getTransactionHistory(payload: {
    skip: number;
    take: number;
    userId: string;
  }): Promise<{ items: UserTransactionHistoryTypes[]; total: number }> {
    try {
      const { skip, take, userId } = payload;
      return (
        await this.requestInstance.get(`/admin/user/transaction/history?skip=${skip}&take=${take}&userId=${userId}`)
      ).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async downloadUserTransactions<T>(payload: { userId: string }): Promise<T> {
    try {
      const { userId } = payload;
      return (
        await this.requestInstance.get(`/admin/user/transaction/history/csv?userId=${userId}`, {
          responseType: 'blob',
        })
      ).data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getUserRecord(payload: {
    skip: number;
    take: number;
    filter: string;
  }): Promise<{ items: UserListType[]; total: number }> {
    try {
      const { skip, take, filter } = payload;
      return (await this.requestInstance.get(`/admin/user/list?skip=${skip}&take=${take}&filter=${filter}`)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async downloadUserRecord<T>(): Promise<T> {
    try {
      return (await this.requestInstance.get(`/admin/user/record`, { responseType: 'blob' })).data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getUserDetails(userId: string): Promise<UserListType> {
    try {
      return (await this.requestInstance.get(`/admin/user/single/${userId}`)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async updateUserStatus(payload: { userId: string; status: boolean }): Promise<string> {
    try {
      const { userId, status } = payload;
      return (await this.requestInstance.put(`/admin/user/status`, { id: userId, activeStatus: status })).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async resetUserPassword(payload: { userId: string }): Promise<string> {
    try {
      const { userId } = payload;
      return (await this.requestInstance.put(`/admin/user/reset/password/${userId}`)).data.dataÏ;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async deleteUser(payload: { userId: string }): Promise<{ message: string }> {
    try {
      const { userId } = payload;
      return (await this.requestInstance.post(`/admin/user/delete/${userId}`)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getUserBalances(payload: { userId: string }): Promise<CurrencyTypes[]> {
    try {
      const { userId } = payload;
      return (await this.requestInstance.get(`/v1/user/user-balances/${userId}`)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getRedemptionRequirements(payload: { userId: string }): Promise<RedemptionTypes> {
    try {
      const { userId } = payload;
      return (await this.requestInstance.get(`/v1/xoxoday/redemption-requirements/${userId}`)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getUserStatistics(payload: { userId: string }): Promise<UserStatisticTypes[]> {
    try {
      const { userId } = payload;
      return (await this.requestInstance.get(`/admin/participant/statistics?userId=${userId}`)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async resetAdminPassword(
    payload: CompleteEmailVerificationPayload & { password: string },
  ): Promise<string> {
    try {
      return (await this.requestInstance.put(`/admin/auth/password/reset`, { ...payload })).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async depositCryptoAddress(payload: {
    filterSymbol: string;
    network: string;
  }): Promise<DepositAddressTypes> {
    try {
      const { filterSymbol, network } = payload;
      return (await this.requestInstance.get(`/admin/tatum/deposit/address?symbol=${filterSymbol}&network=${network}`))
        .data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getCampaignParticipants(payload: ParticipantListParams): Promise<ParticipantListTypes> {
    try {
      const { campaignId, filter, skip, take, sort } = payload;
      return (
        await this.requestInstance.get(
          `/admin/participant/all?skip=${skip}&take=${take}&campaignId=${campaignId}&filter=${filter}&sort=${sort}`,
        )
      ).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async blacklistParticipant(payload: { participantId: string }): Promise<string> {
    try {
      const { participantId } = payload;
      return await this.requestInstance.put(`/admin/participant/blacklist/${participantId}`);
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async downloadParticipantsRecord<T>(payload: { campaignId: string }): Promise<T> {
    try {
      const { campaignId } = payload;
      return (
        await this.requestInstance.get(`/admin/participant/list/download/${campaignId}`, {
          responseType: 'blob',
        })
      ).data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async downloadCampaignsMetrics<T>(): Promise<T> {
    try {
      return (
        await this.requestInstance.get(`/admin/campaign/metrics/download`, {
          responseType: 'blob',
        })
      ).data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async linkTiktok<T>(authCode: string): Promise<T> {
    try {
      return (await this.requestInstance.post(`/admin/social/link/tiktok`, { code: authCode })).data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getAIModels(): Promise<AIModel[]> {
    try {
      return (await this.requestInstance.get(`/v1/ai/model/list`)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async updateCredits(payload: UpdateCreditsPayload): Promise<boolean> {
    try {
      const response = await this.requestInstance.post('/admin/credits/username', payload);
      return response.data.data.success;
    } catch (error) {
      return false;
    }
  }

  public static async updateAdminPassword(payload: { password: string; idToken: string }): Promise<boolean> {
    try {
      const response = await this.requestInstance.put('/admin/auth/password/update', payload);
      return response.data.data.success;
    } catch (error) {
      return false;
    }
  }

  public static async getCoiinMetrics(): Promise<CoiinMetrics> {
    try {
      return (await this.requestInstance.get(`/public/coiin/metrics`)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async deleteCampaign(campaignId: string): Promise<boolean> {
    try {
      return (await this.requestInstance.delete(`/admin/campaign?campaignId=${campaignId}`)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async payoutCampaignRewards(payload: { campaignId: string; rejected?: string[] }): Promise<boolean> {
    try {
      return (
        await this.requestInstance.put(`/admin/campaign/payout/rewards/${payload.campaignId}`, {
          rejected: payload.rejected,
        })
      ).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async getWithdrawalRequests(payload: {
    skip: number;
    take?: number;
  }): Promise<{ items: WithdrawalRequest[]; total: number }> {
    try {
      return (
        await this.requestInstance.get(`/admin/tatum/withdrawal/requests?skip=${payload.skip}&take=${payload.take}`)
      ).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async updateWithdrawalRequests(payload: {
    status: APPROVAL_STATUS;
    transferId: string;
  }): Promise<boolean> {
    try {
      return (await this.requestInstance.put(`/admin/tatum/withdrawal/request/update`, payload)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }

  public static async approveKyc(payload: { userId: string; level: KYC_LEVEL }): Promise<string> {
    try {
      return (await this.requestInstance.post('/admin/kyc/user/approve', payload)).data.data;
    } catch (error) {
      throw new Error((error as AxiosError).response?.data?.message || SOMETHING_WENT_WRONG);
    }
  }
}
