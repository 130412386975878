import React, { useEffect, useState } from 'react';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { CoiinReducerTypes, PaymentMethodTypes } from '../../types';
import { capitalize } from '../../helpers/formatter';
import CustomButton from '../CustomButton';
import { ApiClient } from '../../services/apiClient';
import { showErrorAlert, showSuccessAlert } from '../../store/actions/alerts';
import { useDispatch, useSelector } from 'react-redux';
import './stripePurchaseForm.scss';
import CustomInput from '../CustomInput';
// import { fetchCoiinValue } from '../../store/middlewares';

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  givenAmount?: number;
  handleRefreshBalance: (value: boolean) => void;
}

export const StripePurchaseForm: React.FC<Props> = ({ setOpen, givenAmount, handleRefreshBalance }) => {
  const dispatch = useDispatch();
  const { coiinValue, loading } = useSelector((state: { coiin: CoiinReducerTypes }) => state.coiin);
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [amount, setAmount] = useState(givenAmount || 0);
  const [openCards, setOpenCards] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodTypes[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [purchaseCoiinLoading, setPurchaseCoiinLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    ApiClient.getPaymentMethods()
      .then((res) => setPaymentMethods(res))
      .catch((err) => dispatch(showErrorAlert(err.message)))
      .finally(() => setIsLoading(false));
  }, []);

  //   useEffect(() => {
  //     dispatch(fetchCoiinValue());
  //   }, []);

  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(Number(event.target.value));
  };
  const handleClick = (paymentId: string, displayName: string) => {
    setPaymentMethodId(paymentId);
    setDisplayName(displayName);
  };
  const handleCloseCards = () => {
    setOpenCards(false);
  };

  const handleOpenCards = () => {
    setOpenCards(true);
  };

  const handlePurchase = async () => {
    if (amount * parseFloat(coiinValue) < 0.5)
      return dispatch(showErrorAlert('The minimum amount limit is $0.50 or equivalent currency'));
    try {
      setPurchaseCoiinLoading(true);
      ApiClient.purchaseCoiin({ amount, paymentMethodId })
        .then(() => {
          setOpen(false);
          dispatch(showSuccessAlert('Purchase coiin successfull'));
          handleRefreshBalance(true);
        })
        .catch((err) => dispatch(showErrorAlert(err.message)))
        .finally(() => setPurchaseCoiinLoading(false));
    } catch (e) {
      console.log(e);
      setPurchaseCoiinLoading(false);
    }
  };

  return (
    <div className="stripePurchaseFormWrapper">
      <div className="inputFieldWrapper">
        <CustomInput
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          name={'amount'}
          label={'Amount (Coiin)'}
          defaultValue={amount}
          onChange={handleChange}
          placeholder="The minimum amount limit is $0.50 or equivalent currency"
          variant={'outlined'}
        />
        {coiinValue && !loading && <p>(${(amount !== 0 ? amount * parseFloat(coiinValue) : 0).toFixed(2)})</p>}
      </div>
      <div className="my-6">
        {isLoading ? (
          <CircularProgress size={22} />
        ) : (
          <FormControl className="w-full">
            <InputLabel variant="outlined">Select Card</InputLabel>
            <Select
              open={openCards}
              className="selectField"
              onClose={handleCloseCards}
              onOpen={handleOpenCards}
              value={displayName}
              renderValue={() => displayName}
              variant="outlined"
            >
              {paymentMethods?.map((wallet, index) => {
                return (
                  <MenuItem
                    onClick={() => handleClick(wallet.id, `${capitalize(wallet.brand)} (...${wallet.last4})`)}
                    key={index}
                  >
                    {capitalize(wallet.brand)} {`(...${wallet.last4})`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </div>
      <div className="buttonWrapper">
        <CustomButton onClick={handleCloseDialog} className="cancleButton">
          Cancel
        </CustomButton>
        <CustomButton onClick={handlePurchase} className="submitButton" loading={purchaseCoiinLoading}>
          Purchase
        </CustomButton>
      </div>
    </div>
  );
};
