import React, { FC, useState } from 'react';
import { AppBar, Paper, Tab, Tabs } from '@material-ui/core';
import { TabPanel } from '../TabPanel';
import CampaignTable from './CampaignTable';
import PostCampaigns from './PostCampaigns';
import PendingCampaigns from '../../componentsv2/PendingCampaigns';
import { useLocation } from 'react-router-dom';
import CustomButton from '../CustomButton';
import buttonStyles from '../../assets/styles/customButton.module.css';
import { ApiClient } from '../../services/apiClient';
import fileDownload from 'js-file-download';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../store/actions/alerts';

const CampaignsTab: FC = () => {
  const location: { state: number } = useLocation();
  const [value, setValue] = useState<number>(location.state ? location.state : 0);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const downloadRecords = () => {
    setDownloadLoading(true);
    ApiClient.downloadCampaignsMetrics<string>()
      .then((response) => {
        fileDownload(response, 'campaigns-list.csv');
      })
      .catch((error) => dispatch(showErrorAlert((error as Error).message)))
      .finally(() => setDownloadLoading(false));
  };

  return (
    <div>
      <div className="flex flex-row justify-end align-top mb-1">
        <CustomButton className={buttonStyles.buttonPrimary} onClick={downloadRecords} loading={downloadLoading}>
          Download Campaigns Data
        </CustomButton>
      </div>
      <div className="mb-2">
        <AppBar elevation={1} position="static" color="transparent">
          <Tabs
            TabIndicatorProps={{ style: { backgroundColor: '#1d3faf' } }}
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Current Campaigns" {...a11yProps(0)} className="focus:outline-none" />
            <Tab label="Past Campaigns" {...a11yProps(1)} className="focus:outline-none" />
            <Tab label="Pending Campaigns" {...a11yProps(2)} className="focus:outline-none" />
          </Tabs>
        </AppBar>
      </div>

      <Paper className="paper">
        <TabPanel value={value} index={0}>
          <CampaignTable />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PostCampaigns />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PendingCampaigns />
        </TabPanel>
      </Paper>
    </div>
  );
};

export default CampaignsTab;
