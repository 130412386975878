import React, { ChangeEvent, useState } from 'react';
import './styles.scss';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import { pastCampaigns } from '../../../helpers/mockData';
import MetricsCard from '../../../componentsv2/TTCM/Dashboard/MetricsCard';
import CreatorsTable from '../../../componentsv2/TTCM/Dashboard/CreatorsTable';
import { calculateTTCMInfluence } from '../../../helpers/utils';
import { CustomEventTarget } from '../../../types';

const TTCMDashboard: React.FC = () => {
  const [campaign, setCampaign] = useState(pastCampaigns[0]);
  const [campaignInfluence, setCampaignInfluence] = useState(
    calculateTTCMInfluence(campaign.pointValues, campaign.metrics),
  );

  const handleCampaignChange = (event: ChangeEvent<unknown>) => {
    const foundCampaign = pastCampaigns.find(
      (item) => item.name === (event.target as unknown as CustomEventTarget).value,
    );
    if (foundCampaign) {
      setCampaign(foundCampaign);
      setCampaignInfluence(calculateTTCMInfluence(foundCampaign.pointValues, foundCampaign.metrics));
    }
  };

  return (
    <div className="ttcmDashboardContainer">
      <div className="selectInputWrapper">
        <h1 className="title">Campaign Metrics</h1>
        <CustomSelect
          className="selectInput"
          label="Select Campaign"
          options={pastCampaigns.map((item) => item.name)}
          defaultValue={campaign.name}
          onChange={handleCampaignChange}
        />
      </div>
      <div className="campaignMetricsWrapper">
        <MetricsCard name="budget" value={campaign.budget} />
        <MetricsCard name="influence" value={calculateTTCMInfluence(campaign.pointValues, campaign.metrics)} />
        {Object.keys(campaign.metrics).map((key) => (
          <MetricsCard key={key} name={key} value={campaign.metrics[key]} />
        ))}
      </div>
      <div className="creatorList">
        <h2 className="subtitle">Influence generated by Creators</h2>
        <CreatorsTable
          currency={campaign.currency}
          creators={campaign.creators}
          pointValues={campaign.pointValues}
          campaignInfluence={campaignInfluence}
          budget={campaign.budget}
        />
      </div>
    </div>
  );
};

export default TTCMDashboard;
