import React, { ChangeEvent, useEffect, useState } from 'react';
import { CircularProgress, Tooltip } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { showErrorAlert, showSuccessAlert } from '../../../store/actions/alerts';
// import ContentCopyIcon from '@material-ui/icons/FileCopy';
import './depositCryptoForm.scss';
import CopyIcon from '../../../assets/svg/copyIcon.svg';
import SelectCurrency from '../../../componentsv2/SelectCurrency';
import { DepositAddressTypes, FundingWallet } from '../../../types';
import { ApiClient } from '../../../services/apiClient';

interface Props {
  cryptoList: { symbol: string; network: string }[] | undefined;
  callback?: () => void;
  fundingWallet: FundingWallet[];
}

const DepositCryptoForm: React.FC<Props> = ({ cryptoList, callback, fundingWallet }) => {
  const [symbol, setSymbol] = useState<string>('COIIN-BSC');
  const options = cryptoList?.map((item) => `${item.symbol}-${item.network}`) || [];
  const dispatch = useDispatch();
  const [depositAddress, setDepositAddress] = useState<DepositAddressTypes>();
  const [loading, setLoading] = useState(false);
  const [updatedCurrency, setUpdatedCurrency] = useState(false);
  const [currencyValue, setCurrencyValue] = useState<string | null>('');

  // Fetch deposit address for symbol
  const fetchData = () => {
    setLoading(true);
    const filterSymbol = symbol.split('-')[0];
    const network = symbol.split('-')[1];
    ApiClient.depositCryptoAddress({ filterSymbol, network })
      .then((response) => {
        setDepositAddress(response);
        setLoading(false);
        const found = fundingWallet?.find((item) => symbol === `${item.type}-${item.network}`);
        if (callback && ((fundingWallet.length && !found) || !fundingWallet.length)) callback();
      })
      .catch((error) => dispatch(showErrorAlert((error as Error).message)))
      .finally(() => setLoading(false));
  };

  useEffect(fetchData, [updatedCurrency]);

  const copyAddress = () => {
    copy(depositAddress?.address || '');
    dispatch(showSuccessAlert('Address copied to Clipboard!'));
  };

  const handleCurrencyValue = (event: ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    if (newValue) {
      setCurrencyValue(newValue);
      setSymbol(newValue);
      setUpdatedCurrency(!updatedCurrency);
    }
  };

  return (
    <div className="depositCryptoFormWrapper">
      <h3 className="depositCryptoTitle">Deposit Crypto</h3>
      <div className="depositSelectField">
        <SelectCurrency
          label="Select Token to Deposit"
          value={currencyValue}
          options={options}
          handleOnChange={handleCurrencyValue}
        />
      </div>

      <div className="depositAddressWrapper">
        {depositAddress?.fromTatum ? (
          <p className="depositAddress">Please send selected token to this address:</p>
        ) : (
          <p className="depositAddress">
            Please send selected token from one of your claimed addresses to this address:
          </p>
        )}
        {loading || !depositAddress ? (
          <CircularProgress size={22} color="primary" className="mt-3" />
        ) : (
          <>
            {depositAddress?.memo && <p className="depositAddress">Memo: {depositAddress.memo}</p>}
            {depositAddress?.message && <p className="depositAddress">Message: {depositAddress.message}</p>}
            {depositAddress?.destinationTag && (
              <p className="depositAddress">DestinationTag: {depositAddress.destinationTag}</p>
            )}
            <div className="address ">
              <p>{depositAddress?.address || ''}</p>
              <Tooltip title="Copy Address" placement="top">
                <span onClick={copyAddress}>
                  <img src={CopyIcon} className="copyIcon" />
                </span>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DepositCryptoForm;
