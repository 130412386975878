import React, { FC, useState } from 'react';
import styles from './userList.module.css';
import headingStyles from '../../assets/styles/heading.module.css';
import buttonStyles from '../../assets/styles/customButton.module.css';
import CustomButton from '../CustomButton';
import { useDispatch } from 'react-redux';
import { showErrorAlert, showSuccessAlert } from '../../store/actions/alerts';
import { ApiClient } from '../../services/apiClient';

type IProps = {
  userId: string;
};

const AddSubscription: FC<IProps> = ({ userId }: IProps) => {
  const dispatch = useDispatch();
  const [weeks, setWeeks] = useState(1);
  const [addLoading, setAddLoading] = useState(false);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWeeks(parseInt(e.target.value));
  };

  const handleCoiinTransfer = async () => {
    setAddLoading(true);
    ApiClient.addSubscription({ weeks, userId })
      .then((response) => {
        dispatch(showSuccessAlert(response.message));
      })
      .catch((err) => dispatch(showErrorAlert(err.message)))
      .finally(() => setAddLoading(false));
  };

  return (
    <div className={styles.transferWrapper}>
      <h2 className={headingStyles.headingSm}>Add Subscription</h2>
      <label>Number of Weeks:</label>
      <input
        type="number"
        name="week"
        value={weeks}
        min={1}
        step={1}
        className={styles.coiinInputField}
        placeholder="Number of weeks"
        onChange={handleOnChange}
      />
      <div className={buttonStyles.buttonWrapper}>
        <CustomButton
          className={`${buttonStyles.buttonPrimary} ${styles.actionButton}`}
          onClick={handleCoiinTransfer}
          loading={addLoading}
        >
          Submit
        </CustomButton>
      </div>
    </div>
  );
};

export default AddSubscription;
