import React, { FC, useState } from 'react';
import styles from './userList.module.css';
import headingStyles from '../../assets/styles/heading.module.css';
import buttonStyles from '../../assets/styles/customButton.module.css';
import CustomButton from '../CustomButton';
import { useDispatch } from 'react-redux';
import { showErrorAlert, showSuccessAlert } from '../../store/actions/alerts';
import { ApiClient } from '../../services/apiClient';

type IProps = {
  userId: string;
};

const TransferCoiin: FC<IProps> = ({ userId }: IProps) => {
  const dispatch = useDispatch();
  const [coiin, setCoiin] = useState('');
  const [addLoading, setAddLoading] = useState(false);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCoiin(e.target.value);
  };

  const handleCoiinTransfer = async () => {
    setAddLoading(true);
    ApiClient.transferCoiin({ amount: coiin, userId })
      .then(() => {
        dispatch(showSuccessAlert('Coiin Transfer added.'));
      })
      .catch((err) => dispatch(showErrorAlert(err.message)))
      .finally(() => setAddLoading(false));
  };

  return (
    <div className={styles.transferWrapper}>
      <h2 className={headingStyles.headingSm}>Transfer Coiin</h2>
      <label>Coiin Amount:</label>
      <input
        type="number"
        name="amount"
        value={coiin}
        className={styles.coiinInputField}
        placeholder="Amount"
        onChange={handleOnChange}
      />
      <div className={buttonStyles.buttonWrapper}>
        <CustomButton
          className={`${buttonStyles.buttonPrimary} ${styles.actionButton}`}
          onClick={handleCoiinTransfer}
          loading={addLoading}
        >
          Transfer
        </CustomButton>
      </div>
    </div>
  );
};

export default TransferCoiin;
