import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { CardSection } from '../CardSection';
import CustomButton from '../CustomButton';
import { useDispatch } from 'react-redux';
import { showErrorAlert, showSuccessAlert } from '../../store/actions/alerts';
import { ApiClient } from '../../services/apiClient';
import './cardSetupForm.scss';
import { ErrorWithMessage } from '../../types';

interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  callback?: () => void;
  open?: boolean;
}

export const CardSetupForm: React.FC<Props> = ({ setModal, callback }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleClose = () => {
    setModal(false);
  };

  const addPaymentMethod = async () => {
    try {
      return await ApiClient.addPaymentMethod();
    } catch (error: unknown) {
      dispatch(showErrorAlert((error as ErrorWithMessage).message));
    }
  };

  const handleSubmit = async () => {
    // event.preventDefault();
    setLoading(true);
    const data = await addPaymentMethod();
    // Make sure to disable form submission until Stripe.js has loaded.
    if (!stripe || !elements) return;
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) throw new Error('null card element');
    if (data?.clientSecret) {
      const result = await stripe.confirmCardSetup(data.clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });
      if (result.error) {
        dispatch(showErrorAlert('The credit card you have entered is invalid'));
        console.log('card error: ', result.error.message);
        setLoading(false);
      } else {
        setLoading(false);
        dispatch(showSuccessAlert('Successfully added payment method'));
        callback && callback();
      }
    } else {
      dispatch(showErrorAlert('Something went worng'));
      setLoading(false);
    }
    handleClose();
  };

  return (
    <div className="cardSetupFormWrapper">
      <h2>Add Credit Card</h2>
      <CardSection />
      <div className="buttonWrapper">
        <CustomButton className="cancleButton" onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton className="submitButton" type={'submit'} loading={loading} onClick={handleSubmit}>
          Submit
        </CustomButton>
      </div>
    </div>
  );
};
